








































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth'
import ActionFeedbackDisplay from '@/components/ActionFeedbackDisplay.vue'

export default Vue.extend({
  name: 'Login',
  components: {
    ActionFeedbackDisplay,
  },
  data() {
    return {
      email: '',
      emailRules: [
        (v: string) =>
          !v ||
          /[A-Za-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])/.test(
            v
          ) ||
          'E-mail must be valid',
      ],
      isValid: true,
    }
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  watch: {
    getUser() {
      if (this.getUser) {
        this.$router.push('account')
      }
    },
  },
  methods: {
    ...mapActions(['logInUser', 'logInUserWithEmailLink']),
    googleLoginClick() {
      this.logInUser()
    },
    emailLinkLoginClick() {
      // need to cast the form ref: https://stackoverflow.com/questions/52109471/typescript-in-vue-property-validate-does-not-exist-on-type-vue-element
      ;(
        this.$refs.logInUserWithEmailLinkForm as Vue & {
          validate: () => boolean
        }
      ).validate()
      if (this.isValid) {
        this.logInUserWithEmailLink(this.email)
      }
    },
    logOutClick() {
      // this.logOutUser() // executes on logout page
      this.$router.push('logout').catch((error) => {
        console.log(error)
      }) // catch error otherwise throws error in console; error is usually because user is already on the page
    },
  },
  created() {
    const auth = getAuth()
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn') || ''
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email =
          window.prompt('Please provide your email for confirmation') || ''
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then(function (result) {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn')
          console.log(result.user)
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function (error) {
          console.log(error)
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        })
    }
  },
})
