






import Vue from 'vue'
import Login from '@/components/Login.vue'

export default Vue.extend({
  name: 'LoginPage',
  components: {
    Login,
  },
})
